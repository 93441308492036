
.App
{
	width:100%;
	height:100%;
	display:flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
}

.App .Holder
{
	width:100%;
	height:100%;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.App .Content 
{
	flex-grow: 1;
	display:flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	padding: 32px;

	>*{
		margin-bottom: 16px;
	}

	.CustomerSelect 
	{
		width: 250px;    
	}
}