.Dropzone 
{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 16px;
	padding: 24px;

	&.Highlight
	{
		background-color: rgb(188, 185, 236);
	}

	.FileInput
	{
		display: none;
  	}
}