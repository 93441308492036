
.JobTableContainer
{
	padding:8px;
	flex-grow: 1;
	display:flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	max-height: 500px;

	.JobTable
	{
		flex-grow:1;
	}

	button 
	{
		margin-bottom: 4px;
		margin-top: 4px;
		width:100%;
	}

	.MuiTableCell-sizeSmall
	{
		min-width: 115px;
		max-width:	250px;
		word-break: break-word;
	}
}