.JobContainer 
{
	//margin-top: 32px;
	display:flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: stretch;
	min-width: 900px;
	position: relative;

	.UpdatingProgressContainer
	{
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		display:flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(255, 255, 255, 0.9);
	}
}

.JobCommands
{
	padding:8px;
	width: 280px;
	display:flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;

	button {
		margin-bottom: 4px;
		margin-top: 4px;
	}
}