.UploadJobDialog .MuiDialogContent-root
{
	align-items: stretch;
	justify-content: stretch;
	display: flex;
	flex-direction: column;
}

.Files
{
	max-height: 400px;
	width: 400px;
	overflow: auto;

	.FileRow
	{
		padding: 8px;
		margin: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.Filename
		{
			flex-grow: 1;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.FileCancel
		{
			min-width: 36px;
		}
	}

	.MuiLinearProgress-root
	{
		padding-top:4px;
		margin-top:4px;
	}
}

.IndeterminateProgressContainer
{
	flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}