.ScanButtonContainer
{
    position: relative;

    .ScanLoader
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.5);
    }
}