.FieldGroup
{
	margin: 16px;
	padding: 16px;
}

.ExistingContentPanel
{
	display: flex;
	justify-content: stretch;
	flex-direction: row;

	>* {
		flex-grow: 1;
		padding: 4px;
	}
}


.NewCustomerPanel
{
	display: flex;
	justify-content: stretch;
	flex-direction: column;
}